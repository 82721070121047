import Vue from 'vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../public/assets/css/site.css'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Dynamic title updates
router.beforeEach((to, from, next) => {
  const main = 'CrushMatics'
  document.title = (to.meta.title) 
    ? [to.meta.title, '|', main].join(' ')
    : main
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
