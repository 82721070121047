<template>
  <div id="header">
    <header class="p-3 text-white">
      <div class="container">
        <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <router-link :to="{ name: 'Home' }" class="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
            <img src="/assets/img/logo.png" alt="">
          </router-link>

          <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            <!-- <li> <router-link :to="{ name: 'Home' }" class="nav-link px-2 text-secondary">Home</router-link> </li>
            <li><a href="#" class="nav-link px-2 text-white">Features</a></li>
            <li><a href="#" class="nav-link px-2 text-white">Pricing</a></li>
            <li><a href="#" class="nav-link px-2 text-white">FAQs</a></li>
            <li><a href="#" class="nav-link px-2 text-white">About</a></li> -->
          </ul>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style lang="less" scoped>
header {
  background-color: #005f40;
}
</style>
