<template>
  <div class="home">
    <Header/>
    <main>
      <section class="py-5 text-center container">
        <div class="row py-lg-5">
          <div class="col-lg-6 col-md-8 mx-auto">
            <h1 class="fw-light">CrushMatics</h1>
            <p class="lead text-muted">still being worked on</p>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'Home',
  components: {
    Header
  }
}
</script>
